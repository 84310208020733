/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AdditionalFeeFeatureOptions {
  PART_OF_RENT = "PART_OF_RENT",
  REFUNDABLE = "REFUNDABLE",
}

export enum AmendmentDetailOption {
  BED = "BED",
  CANCELLATION = "CANCELLATION",
  GUARANTOR = "GUARANTOR",
  INSTALLMENT = "INSTALLMENT",
  SERVICE = "SERVICE",
  TAKEOVER = "TAKEOVER",
  TENANCY_CHANGE_ENTIRELY = "TENANCY_CHANGE_ENTIRELY",
  TENANCY_EXTEND_EARLY_CHECK_IN = "TENANCY_EXTEND_EARLY_CHECK_IN",
  TENANCY_EXTEND_LATER_CHECK_OUT = "TENANCY_EXTEND_LATER_CHECK_OUT",
  TENANCY_SHORTEN_EARLY_CHECK_OUT = "TENANCY_SHORTEN_EARLY_CHECK_OUT",
  TENANCY_SHORTEN_LATER_CHECK_IN = "TENANCY_SHORTEN_LATER_CHECK_IN",
}

export enum AmendmentDetailStatusOption {
  COMPLETED = "COMPLETED",
  CONFIRMED = "CONFIRMED",
  EXPIRED = "EXPIRED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

export enum AmendmentMilestoneOption {
  DETAILS_COMPLETED = "DETAILS_COMPLETED",
  SELECTION_COMPLETED = "SELECTION_COMPLETED",
  TERMS_ACCEPTED = "TERMS_ACCEPTED",
}

export enum AmendmentTypeOption {
  BED = "BED",
  END_DATE = "END_DATE",
  INSTALLMENT = "INSTALLMENT",
  SERVICE = "SERVICE",
  START_DATE = "START_DATE",
}

export enum AreaUnitOfMeasurementOptions {
  SQFT = "SQFT",
  SQM = "SQM",
}

export enum BathroomArrangementOptions {
  PRIVATE = "PRIVATE",
  SHARED = "SHARED",
}

export enum BathroomTypeOptions {
  FULL_BATH = "FULL_BATH",
  HALF_BATH = "HALF_BATH",
  THREE_QUARTERS_BATH = "THREE_QUARTERS_BATH",
}

export enum BedStatusOptions {
  AVAILABLE = "AVAILABLE",
  BLOCKED = "BLOCKED",
  BOOKED = "BOOKED",
  HELD = "HELD",
  HIDDEN = "HIDDEN",
  MAINTENANCE = "MAINTENANCE",
  NOMINATED = "NOMINATED",
  OCCUPIED = "OCCUPIED",
  PENDING = "PENDING",
  RESERVED = "RESERVED",
  TURNAROUND = "TURNAROUND",
  UNAVAILABLE = "UNAVAILABLE",
}

export enum BillingCycleOptions {
  DAILY = "DAILY",
  MONTHLY = "MONTHLY",
  WEEKLY = "WEEKLY",
}

export enum BookingFormStatusOption {
  ACCEPTED = "ACCEPTED",
  ACKNOWLEDGED = "ACKNOWLEDGED",
  COMPLETED = "COMPLETED",
  DISPLAYED = "DISPLAYED",
  INCOMPLETE = "INCOMPLETE",
  REJECTED = "REJECTED",
}

export enum BookingLinkStatusTypeOption {
  COMPLETED = "COMPLETED",
  EXPIRED = "EXPIRED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

export enum BookingLinkTypeOption {
  TAKEOVER = "TAKEOVER",
}

export enum BookingStatusOption {
  APPLIED = "APPLIED",
  BOOKING_COMPLETED = "BOOKING_COMPLETED",
  BOOKING_CONFIRMED = "BOOKING_CONFIRMED",
  CANCELLED = "CANCELLED",
  DETAILS_COMPLETED = "DETAILS_COMPLETED",
  ENQUIRY = "ENQUIRY",
  EXPIRED = "EXPIRED",
  PAST = "PAST",
  RESIDENT = "RESIDENT",
  ROOM_SELECTED = "ROOM_SELECTED",
  SELECTION_COMPLETED = "SELECTION_COMPLETED",
  TERMS_ACCEPTED = "TERMS_ACCEPTED",
}

export enum CardBrandOptions {
  AMEX = "AMEX",
  DELTA = "DELTA",
  DINERS = "DINERS",
  DISCOVER = "DISCOVER",
  JCB = "JCB",
  MASTERCARD = "MASTERCARD",
  OTHER = "OTHER",
  UNIONPAY = "UNIONPAY",
  UNKNOWN = "UNKNOWN",
  VISA = "VISA",
}

export enum CardVaultStatusOptions {
  INVALID = "INVALID",
  VALID = "VALID",
}

export enum CategoryOption {
  DIRECT_LET = "DIRECT_LET",
  NOMINATION = "NOMINATION",
}

export enum ChargeStatusOptions {
  CREATED = "CREATED",
  FAILED = "FAILED",
  INITIATED = "INITIATED",
  SUCCEEDED = "SUCCEEDED",
}

export enum ContactTypeOptions {
  EMERGENCY = "EMERGENCY",
  GUARANTOR = "GUARANTOR",
}

export enum ContractStageOption {
  AMENDMENT_AFTER_CHECK_IN = "AMENDMENT_AFTER_CHECK_IN",
  BOOKING_AMENDMENT = "BOOKING_AMENDMENT",
  BOOKING_CONFIRMATION = "BOOKING_CONFIRMATION",
}

export enum ContractStatusOption {
  ACCEPTED = "ACCEPTED",
  CANCELLED = "CANCELLED",
  EXPIRED = "EXPIRED",
  GENERATED = "GENERATED",
  REJECTED = "REJECTED",
}

export enum CreditCategoryOptions {
  NON_RENT_PROMOTION = "NON_RENT_PROMOTION",
  RENT_PROMOTION = "RENT_PROMOTION",
}

export enum CreditTransactionCategoryOptions {
  NON_RENT_PROMOTION = "NON_RENT_PROMOTION",
  RENT_PROMOTION = "RENT_PROMOTION",
}

export enum CreditTransactionTypeOptions {
  ADD_CREDIT = "ADD_CREDIT",
  CLAWBACK = "CLAWBACK",
  REMOVE_CREDIT = "REMOVE_CREDIT",
  REVERSAL_CREDIT = "REVERSAL_CREDIT",
  USE_CREDIT = "USE_CREDIT",
}

export enum Currency {
  AED = "AED",
  AFN = "AFN",
  ALL = "ALL",
  AMD = "AMD",
  ANG = "ANG",
  AOA = "AOA",
  ARS = "ARS",
  AUD = "AUD",
  AWG = "AWG",
  AZN = "AZN",
  BAM = "BAM",
  BBD = "BBD",
  BDT = "BDT",
  BGN = "BGN",
  BHD = "BHD",
  BIF = "BIF",
  BMD = "BMD",
  BND = "BND",
  BOB = "BOB",
  BRL = "BRL",
  BSD = "BSD",
  BTN = "BTN",
  BWP = "BWP",
  BYN = "BYN",
  BZD = "BZD",
  CAD = "CAD",
  CDF = "CDF",
  CHF = "CHF",
  CLP = "CLP",
  CNY = "CNY",
  COP = "COP",
  CRC = "CRC",
  CUC = "CUC",
  CUP = "CUP",
  CVE = "CVE",
  CZK = "CZK",
  DJF = "DJF",
  DKK = "DKK",
  DOP = "DOP",
  DZD = "DZD",
  EGP = "EGP",
  ERN = "ERN",
  ETB = "ETB",
  EUR = "EUR",
  FJD = "FJD",
  FKP = "FKP",
  GBP = "GBP",
  GEL = "GEL",
  GHS = "GHS",
  GIP = "GIP",
  GMD = "GMD",
  GNF = "GNF",
  GTQ = "GTQ",
  GYD = "GYD",
  HKD = "HKD",
  HNL = "HNL",
  HRK = "HRK",
  HTG = "HTG",
  HUF = "HUF",
  IDR = "IDR",
  ILS = "ILS",
  INR = "INR",
  IQD = "IQD",
  IRR = "IRR",
  ISK = "ISK",
  JMD = "JMD",
  JOD = "JOD",
  JPY = "JPY",
  KES = "KES",
  KGS = "KGS",
  KHR = "KHR",
  KMF = "KMF",
  KPW = "KPW",
  KRW = "KRW",
  KWD = "KWD",
  KYD = "KYD",
  KZT = "KZT",
  LAK = "LAK",
  LBP = "LBP",
  LKR = "LKR",
  LRD = "LRD",
  LSL = "LSL",
  LYD = "LYD",
  MAD = "MAD",
  MDL = "MDL",
  MGA = "MGA",
  MKD = "MKD",
  MMK = "MMK",
  MNT = "MNT",
  MOP = "MOP",
  MRO = "MRO",
  MUR = "MUR",
  MVR = "MVR",
  MWK = "MWK",
  MXN = "MXN",
  MYR = "MYR",
  MZN = "MZN",
  NAD = "NAD",
  NGN = "NGN",
  NIO = "NIO",
  NOK = "NOK",
  NPR = "NPR",
  NZD = "NZD",
  OMR = "OMR",
  PAB = "PAB",
  PEN = "PEN",
  PGK = "PGK",
  PHP = "PHP",
  PKR = "PKR",
  PLN = "PLN",
  PYG = "PYG",
  QAR = "QAR",
  RON = "RON",
  RSD = "RSD",
  RUB = "RUB",
  RWF = "RWF",
  SAR = "SAR",
  SBD = "SBD",
  SCR = "SCR",
  SDG = "SDG",
  SEK = "SEK",
  SGD = "SGD",
  SHP = "SHP",
  SLL = "SLL",
  SOS = "SOS",
  SRD = "SRD",
  SSP = "SSP",
  STD = "STD",
  SVC = "SVC",
  SYP = "SYP",
  SZL = "SZL",
  THB = "THB",
  TJS = "TJS",
  TMT = "TMT",
  TND = "TND",
  TOP = "TOP",
  TRY = "TRY",
  TTD = "TTD",
  TWD = "TWD",
  TZS = "TZS",
  UAH = "UAH",
  UGX = "UGX",
  USD = "USD",
  UYU = "UYU",
  UZS = "UZS",
  VEF = "VEF",
  VND = "VND",
  VUV = "VUV",
  WST = "WST",
  XAF = "XAF",
  XAG = "XAG",
  XAU = "XAU",
  XBA = "XBA",
  XBB = "XBB",
  XBC = "XBC",
  XBD = "XBD",
  XCD = "XCD",
  XDR = "XDR",
  XOF = "XOF",
  XPD = "XPD",
  XPF = "XPF",
  XPT = "XPT",
  XSU = "XSU",
  XTS = "XTS",
  XUA = "XUA",
  XXX = "XXX",
  YER = "YER",
  ZAR = "ZAR",
  ZMW = "ZMW",
  ZWL = "ZWL",
}

export enum CustomFieldFieldFormatOptions {
  PICKLIST = "PICKLIST",
  SHORT_TEXT = "SHORT_TEXT",
}

export enum CustomFieldModelTypeOptions {
  BOOKING = "BOOKING",
  EMERGENCY_CONTACT = "EMERGENCY_CONTACT",
  GUARANTOR_CONTACT = "GUARANTOR_CONTACT",
  STUDENT = "STUDENT",
}

export enum CustomFieldTypeOptions {
  EXTERNAL_ID = "EXTERNAL_ID",
  FINANCE_TAG = "FINANCE_TAG",
  PERSONAL_INFORMATION = "PERSONAL_INFORMATION",
  REBOOKER_TAG = "REBOOKER_TAG",
}

export enum DeviceOption {
  DESKTOP = "DESKTOP",
  MOBILE = "MOBILE",
}

export enum DietaryPreferenceOptions {
  NOT_SPECIFIC = "NOT_SPECIFIC",
  VEGETARIAN = "VEGETARIAN",
}

export enum DiscountItemTypeOptions {
  RENT_ONLY = "RENT_ONLY",
  TOTAL_RENT = "TOTAL_RENT",
}

export enum DiscountRuleOptions {
  GROSS_VALUE = "GROSS_VALUE",
  NET_VALUE = "NET_VALUE",
}

export enum DocumentStatusOption {
  CONFIRMED = "CONFIRMED",
  REJECTED = "REJECTED",
  UPLOADED = "UPLOADED",
}

export enum FieldFormatOptions {
  BOOLEAN = "BOOLEAN",
  DATE = "DATE",
  PICKLIST = "PICKLIST",
  SHORT_TEXT = "SHORT_TEXT",
}

export enum FieldTypeOptions {
  CUSTOMIZED = "CUSTOMIZED",
  DEFAULT = "DEFAULT",
}

export enum FinancialStatusOptions {
  IN_ARREARS = "IN_ARREARS",
  IN_BALANCE = "IN_BALANCE",
  IN_CREDIT = "IN_CREDIT",
}

export enum FormSettingTypeOption {
  ACCEPT_OR_REJECT = "ACCEPT_OR_REJECT",
  ACKNOWLEDGE = "ACKNOWLEDGE",
  CUSTOMIZED_INPUT = "CUSTOMIZED_INPUT",
  DISPLAY = "DISPLAY",
}

export enum GenderOptions {
  FEMALE = "FEMALE",
  MALE = "MALE",
  NEUTRAL = "NEUTRAL",
  OTHER = "OTHER",
}

export enum GenderPreferenceOptions {
  CO_ED = "CO_ED",
  FEMALE_ONLY = "FEMALE_ONLY",
  MALE_ONLY = "MALE_ONLY",
  NOT_AVAILABLE = "NOT_AVAILABLE",
}

export enum IncrementTypeOptions {
  FIXED_AMOUNT = "FIXED_AMOUNT",
  PERCENTAGE = "PERCENTAGE",
}

export enum InputTypeInFormOptions {
  DROPDOWN = "DROPDOWN",
  RADIO = "RADIO",
}

export enum InspectionItemCategoryOptions {
  BEDROOM = "BEDROOM",
  EN_SUITE = "EN_SUITE",
  KITCHEN_AREA = "KITCHEN_AREA",
}

export enum InspectionItemStatusOptions {
  FAILED = "FAILED",
  NOT_EXISTED = "NOT_EXISTED",
  PASSED = "PASSED",
}

export enum InstallmentTypeOption {
  CUSTOMIZED = "CUSTOMIZED",
  FORTNIGHTLY = "FORTNIGHTLY",
  FULL = "FULL",
  MONTHLY = "MONTHLY",
  WEEKLY = "WEEKLY",
}

export enum KitchenArrangementOptions {
  PRIVATE = "PRIVATE",
  SHARED = "SHARED",
}

export enum KitchenTypeOptions {
  ELECTRIC = "ELECTRIC",
  GAS = "GAS",
  KITCHENETTE = "KITCHENETTE",
}

export enum LengthUnitOfMeasurementOptions {
  CM = "CM",
  INCH = "INCH",
}

export enum ModeOfArrivalOption {
  DOMESTIC_ARRIVAL = "DOMESTIC_ARRIVAL",
  INTERNATIONAL_ARRIVAL = "INTERNATIONAL_ARRIVAL",
}

export enum ModelOptions {
  BOOKING = "BOOKING",
  EMERGENCY_CONTACT = "EMERGENCY_CONTACT",
  GUARANTOR_CONTACT = "GUARANTOR_CONTACT",
  STUDENT = "STUDENT",
}

export enum OfferStatusOption {
  NOT_OFFERED = "NOT_OFFERED",
  OFFERED = "OFFERED",
}

export enum PartnerTypeOption {
  BOND_AUTHORITY = "BOND_AUTHORITY",
  BUSINESS_GROUP = "BUSINESS_GROUP",
  DEPOSIT_AUTHORITY = "DEPOSIT_AUTHORITY",
  SERVICE_COMPANY = "SERVICE_COMPANY",
  UNIVERSITY = "UNIVERSITY",
  VOUCHER_SUPPLIER = "VOUCHER_SUPPLIER",
}

export enum PaymentChannelOptions {
  STRIPE = "STRIPE",
}

export enum PaymentItemModelOptions {
  ADDITIONAL_FEE = "ADDITIONAL_FEE",
  INSPECTION_REPORT_ITEM = "INSPECTION_REPORT_ITEM",
  INSTALLMENT = "INSTALLMENT",
  TRANSACTION_FEE = "TRANSACTION_FEE",
}

export enum PaymentItemStatusOptions {
  AWAITING = "AWAITING",
  DEPOSITED = "DEPOSITED",
  INVOICED = "INVOICED",
  OPEN = "OPEN",
  OVERDUE = "OVERDUE",
  PAID = "PAID",
  PENDING = "PENDING",
  REFUNDED = "REFUNDED",
  REMAINING = "REMAINING",
  RETURNED = "RETURNED",
  TRANSFERRED = "TRANSFERRED",
  VOID = "VOID",
  WAIVED = "WAIVED",
}

export enum PaymentMethodStatusOptions {
  CREATED = "CREATED",
  DUPLICATED = "DUPLICATED",
  FAILED = "FAILED",
  INITIATED = "INITIATED",
  SUCCEEDED = "SUCCEEDED",
}

export enum PaymentMethodTypeOptions {
  AU_BECS_DEBIT = "AU_BECS_DEBIT",
  BACS_DEBIT = "BACS_DEBIT",
  BANK_TRANSFER = "BANK_TRANSFER",
  CARD = "CARD",
  SEPA_DEBIT = "SEPA_DEBIT",
}

export enum PaymentScenarioOptions {
  AMENDMENT_PROCESS = "AMENDMENT_PROCESS",
  BOOKING_PROCESS = "BOOKING_PROCESS",
  NORMAL_PAYMENT = "NORMAL_PAYMENT",
}

export enum PetPreferenceOptions {
  NOT_PERMITTED = "NOT_PERMITTED",
  NOT_SPECIFIC = "NOT_SPECIFIC",
  PERMITTED = "PERMITTED",
}

export enum PropertyPaymentMethodOptions {
  AU_BECS_DEBIT = "AU_BECS_DEBIT",
  BACS_DEBIT = "BACS_DEBIT",
  BANK_TRANSFER = "BANK_TRANSFER",
  CARD = "CARD",
  RECURRING_PAYMENT = "RECURRING_PAYMENT",
  SEPA_DEBIT = "SEPA_DEBIT",
}

export enum RefundDetailInfoStatus {
  CANCELED = "CANCELED",
  FAILED = "FAILED",
  PENDING = "PENDING",
  PENDING_AFTER_APPROVAL = "PENDING_AFTER_APPROVAL",
  REQUIRES_ACTION = "REQUIRES_ACTION",
  SUCCEEDED = "SUCCEEDED",
}

export enum RefundToStudentChannelOptions {
  OFFLINE = "OFFLINE",
  STRIPE = "STRIPE",
}

export enum RelationshipOptions {
  AUNT = "AUNT",
  COUSIN = "COUSIN",
  FATHER = "FATHER",
  FRIEND = "FRIEND",
  GRANDPARENT = "GRANDPARENT",
  GUARANTOR = "GUARANTOR",
  HUSBAND = "HUSBAND",
  MOTHER = "MOTHER",
  OTHER = "OTHER",
  PARTNER = "PARTNER",
  SIBLING = "SIBLING",
  SPONSOR = "SPONSOR",
  STEP_PARENT = "STEP_PARENT",
  TUTOR = "TUTOR",
  UNCLE = "UNCLE",
  WIFE = "WIFE",
}

export enum RentalTaxesRateTypeOption {
  EXCLUDED = "EXCLUDED",
  INCLUDED = "INCLUDED",
}

export enum RoomCategoryOptions {
  ENTIRE_PLACE = "ENTIRE_PLACE",
  PRIVATE_ROOM = "PRIVATE_ROOM",
  SHARED_ROOM = "SHARED_ROOM",
}

export enum SPCriterionTypeOptions {
  INSTALLMENTS = "INSTALLMENTS",
  ROOM_TYPES = "ROOM_TYPES",
  TENANCY_LENGTH = "TENANCY_LENGTH",
  TENANCY_OPTIONS = "TENANCY_OPTIONS",
  TOTAL_RENT = "TOTAL_RENT",
}

export enum SPDiscountTypeOptions {
  FIXED_AMOUNT = "FIXED_AMOUNT",
  FIXED_NIGHTS = "FIXED_NIGHTS",
  PERCENTAGE = "PERCENTAGE",
}

export enum SalesItemCategoryOption {
  ADMINISTRATION_FEE = "ADMINISTRATION_FEE",
  COMMERCIAL = "COMMERCIAL",
  CONSUMABLE = "CONSUMABLE",
  DAMAGE = "DAMAGE",
  DISCOUNT = "DISCOUNT",
  NOMINATION = "NOMINATION",
  OTHER = "OTHER",
  RENTAL = "RENTAL",
  SERVICE = "SERVICE",
  UTILITY_COSTS = "UTILITY_COSTS",
}

export enum SelectBedDietaryPreferenceOption {
  VEGETARIAN = "VEGETARIAN",
}

export enum SelectBedPetPreferenceOption {
  NOT_PERMITTED = "NOT_PERMITTED",
  PERMITTED = "PERMITTED",
}

export enum SelectBedSmokingPreferenceOption {
  NOT_PERMITTED = "NOT_PERMITTED",
  PERMITTED = "PERMITTED",
}

export enum ServiceTypeOption {
  HIDDEN_TO_STUDENT = "HIDDEN_TO_STUDENT",
  OPEN_FOR_SELECTION = "OPEN_FOR_SELECTION",
}

export enum SmokingPreferenceOptions {
  NOT_PERMITTED = "NOT_PERMITTED",
  NOT_SPECIFIC = "NOT_SPECIFIC",
  PERMITTED = "PERMITTED",
}

export enum SortDirection {
  ASCENDING = "ASCENDING",
  DESCENDING = "DESCENDING",
}

export enum SpecialOfferChannelTypeOptions {
  AUTO_APPLY = "AUTO_APPLY",
  PROMOTION_CODE = "PROMOTION_CODE",
}

export enum StudentCommunicationPreferencesOptions {
  EMAIL = "EMAIL",
  PHONE = "PHONE",
  TEXT_MESSAGE = "TEXT_MESSAGE",
}

export enum StudentExpireBookingReason {
  ALREADY_MADE_A_BOOKING_WITH_US = "ALREADY_MADE_A_BOOKING_WITH_US",
  BOOKED_WITH_COMPETITORS_PBSA = "BOOKED_WITH_COMPETITORS_PBSA",
  BOOKED_WITH_UNIVERSITY_ACCOMMODATION = "BOOKED_WITH_UNIVERSITY_ACCOMMODATION",
  CHANGE_OF_MIND = "CHANGE_OF_MIND",
  DECIDED_NOT_TO_STUDY_NEXT_YEAR = "DECIDED_NOT_TO_STUDY_NEXT_YEAR",
  DECIDED_TO_STUDY_IN_AND_ALTERNATIVE_DESTINATION = "DECIDED_TO_STUDY_IN_AND_ALTERNATIVE_DESTINATION",
  FAILED_TO_ACCEPT_AMENDMENT = "FAILED_TO_ACCEPT_AMENDMENT",
  NOT_A_STUDENT = "NOT_A_STUDENT",
  NOT_READY_TO_BOOK = "NOT_READY_TO_BOOK",
  TOO_EXPENSIVE = "TOO_EXPENSIVE",
}

export enum StudentPayToOptions {
  PARTNER = "PARTNER",
  RESIDENCE = "RESIDENCE",
}

export enum StudentRejectTakeoverReason {
  ALREADY_MADE_A_BOOKING_WITH_US = "ALREADY_MADE_A_BOOKING_WITH_US",
  BOOKED_WITH_COMPETITORS_PBSA = "BOOKED_WITH_COMPETITORS_PBSA",
  BOOKED_WITH_UNIVERSITY_ACCOMMODATION = "BOOKED_WITH_UNIVERSITY_ACCOMMODATION",
  CHANGE_OF_MIND = "CHANGE_OF_MIND",
  DECIDED_NOT_TO_STUDY_NEXT_YEAR = "DECIDED_NOT_TO_STUDY_NEXT_YEAR",
  DECIDED_TO_STUDY_IN_AND_ALTERNATIVE_DESTINATION = "DECIDED_TO_STUDY_IN_AND_ALTERNATIVE_DESTINATION",
  FAILED_TO_ACCEPT_AMENDMENT = "FAILED_TO_ACCEPT_AMENDMENT",
  NOT_A_STUDENT = "NOT_A_STUDENT",
  NOT_READY_TO_BOOK = "NOT_READY_TO_BOOK",
  TOO_EXPENSIVE = "TOO_EXPENSIVE",
}

export enum SymbolOptions {
  GT = "GT",
}

export enum TenancyEndDateTypeOption {
  EQ = "EQ",
  LE = "LE",
}

export enum TenancyLengthOptions {
  FIXED_TERM = "FIXED_TERM",
  ROLLING = "ROLLING",
}

export enum TenancyLengthTypeOption {
  EQ = "EQ",
  GE = "GE",
  LE = "LE",
}

export enum TenancyOptionRateTypeOption {
  MONTHLY = "MONTHLY",
  NIGHTLY = "NIGHTLY",
}

export enum TenancyStartDateTypeOption {
  EQ = "EQ",
  GE = "GE",
}

export enum TenancyTypeOption {
  FIXED = "FIXED",
  FLEXIBLE = "FLEXIBLE",
}

export enum TimeSlotLengthOption {
  HALF_HOUR = "HALF_HOUR",
  ONE_HOUR = "ONE_HOUR",
}

export enum TransactionCategoryOptions {
  ADMINISTRATION_FEE = "ADMINISTRATION_FEE",
  COMMERCIAL = "COMMERCIAL",
  CONSUMABLE = "CONSUMABLE",
  DAMAGE = "DAMAGE",
  DISCOUNT = "DISCOUNT",
  NOMINATION = "NOMINATION",
  OTHER = "OTHER",
  RENTAL = "RENTAL",
  SERVICE = "SERVICE",
}

export enum TransactionChannelOptions {
  BALANCE = "BALANCE",
  BPOINT = "BPOINT",
  BRAINTREE = "BRAINTREE",
  OFFLINE = "OFFLINE",
  OTHER = "OTHER",
  SAGE_PAY = "SAGE_PAY",
  STARREZ = "STARREZ",
  STRIPE = "STRIPE",
}

export enum TransactionMethodOptions {
  AU_BECS_DEBIT = "AU_BECS_DEBIT",
  BACS_DEBIT = "BACS_DEBIT",
  BANK_TRANSFER = "BANK_TRANSFER",
  CASH = "CASH",
  CHEQUE = "CHEQUE",
  CREDIT_CARD = "CREDIT_CARD",
  DEBIT_CARD = "DEBIT_CARD",
  OTHER = "OTHER",
  PAY_TO_STUDY = "PAY_TO_STUDY",
  RENTAL_CREDIT = "RENTAL_CREDIT",
  SEPA_DEBIT = "SEPA_DEBIT",
  WALLET_BALANCE = "WALLET_BALANCE",
}

export enum TransactionTypeOptions {
  ALLOCATION = "ALLOCATION",
  AMENDMENT_CREDIT = "AMENDMENT_CREDIT",
  BALANCE_TRANSFER = "BALANCE_TRANSFER",
  CHARGE = "CHARGE",
  CREDIT_ALLOCATION = "CREDIT_ALLOCATION",
  CREDIT_REFUND = "CREDIT_REFUND",
  CREDIT_TRANSFER_FROM = "CREDIT_TRANSFER_FROM",
  CREDIT_TRANSFER_TO = "CREDIT_TRANSFER_TO",
  DEPOSIT = "DEPOSIT",
  DISPATCH = "DISPATCH",
  PAYMENT = "PAYMENT",
  REDEMPTION = "REDEMPTION",
  REFUND = "REFUND",
  REQUEST = "REQUEST",
  REVERSAL_ALLOCATION = "REVERSAL_ALLOCATION",
  REVERSAL_AMENDMENT_CREDIT = "REVERSAL_AMENDMENT_CREDIT",
  REVERSAL_CHARGE = "REVERSAL_CHARGE",
  REVERSAL_DEPOSIT = "REVERSAL_DEPOSIT",
  REVERSAL_DISPATCH = "REVERSAL_DISPATCH",
  REVERSAL_PAYMENT = "REVERSAL_PAYMENT",
  REVERSAL_REDEMPTION = "REVERSAL_REDEMPTION",
  REVERSAL_REQUEST = "REVERSAL_REQUEST",
  TRANSFER_FROM = "TRANSFER_FROM",
  TRANSFER_TO = "TRANSFER_TO",
}

export enum bankAccountCountryOptions {
  EXCEPT = "EXCEPT",
  INCLUDE = "INCLUDE",
}

export interface GetRentTaxFilterInput {
  propertyId: any;
  academicYearId: any;
  tenancyStartDate: any;
  tenancyEndDate: any;
  tenancyOptionRateType: TenancyOptionRateTypeOption;
  nominationPlanId?: any | null;
}

export interface StudentPersonalDetailInput {
  firstName?: any | null;
  middleName?: string | null;
  lastName?: any | null;
  mobilePhoneAreaCode?: string | null;
  mobilePhoneNumber?: string | null;
  homePhoneAreaCode?: string | null;
  homePhoneNumber?: string | null;
  title?: string | null;
  dateOfBirth?: any | null;
  gender?: GenderOptions | null;
  nationality?: any | null;
  destinationUniversity?: string | null;
  universityCardId?: string | null;
  yearOfStudy?: string | null;
  major?: any | null;
  countryTerritory?: any | null;
  countryTerritoryId?: any | null;
  cityTownId?: any | null;
  cityTown?: any | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  postcode?: string | null;
  degreeClassification?: string | null;
}

export interface applyPromotionCodeInput {
  academicYearId: any;
  promotionCode: any;
  tenancyOptionId?: any | null;
  tenancyStartDate: any;
  tenancyEndDate: any;
  tenancyOptionRateType: TenancyOptionRateTypeOption;
  pricePerNightOriginal?: number | null;
  pricePerMonthOriginal?: number | null;
  roomTypeId: any;
  installmentId?: any | null;
  bypassTenancyOptionCheck?: boolean | null;
  services: serviceInput[];
  nominationPlanId?: any | null;
}

export interface bookingReferralInput {
  referral?: string | null;
}

export interface cancelBankTransferInput {
  bookingId: any;
  paymentItemId: any;
}

export interface countAvailableBedNumberInput {
  roomTypeId: any;
  buildingIds: any[];
  floorIndexes: (string | null)[];
  tenancyOptionId?: any | null;
  tenancyStartDate?: any | null;
  tenancyEndDate?: any | null;
  maxNumOfFlatmates: any;
  genderPreference?: GenderPreferenceOptions | null;
  dietaryPreference?: SelectBedDietaryPreferenceOption | null;
  smokingPreference?: SelectBedSmokingPreferenceOption | null;
  petPreference?: SelectBedPetPreferenceOption | null;
  disabilityAccess?: boolean | null;
  intervalForTurnaround?: any | null;
  nominationPlanId?: any | null;
}

export interface createOutOfRoomRecordInput {
  bookingId: any;
  reason: any;
  awayFrom: any;
  awayTo: any;
}

export interface createStudentEmergencyContactInput {
  relationship: RelationshipOptions;
  firstName: any;
  middleName?: string | null;
  lastName: any;
  title?: string | null;
  dateOfBirth?: any | null;
  countryTerritoryId: any;
  cityTownId?: any | null;
  cityTown?: any | null;
  addressLine1: string;
  addressLine2?: string | null;
  postcode?: string | null;
  mobilePhoneAreaCode: string;
  mobilePhoneNumber: string;
  homePhoneAreaCode?: string | null;
  homePhoneNumber?: string | null;
  contactEmail: string;
  contactWithoutPermission?: boolean | null;
}

export interface deleteBookingDocumentInput {
  documentId: any;
  bookingId: any;
}

export interface getChargeInfoInput {
  bookingId: any;
  paymentItemId: any;
  paymentMethodType: PaymentMethodTypeOptions;
}

export interface getIBANValidatedDataInput {
  studentCountryId: any;
  ibanCode: string;
}

export interface getInstallmentWithPlansInput {
  tenancyOptionId?: any | null;
  tenancyStartDate: any;
  tenancyEndDate: any;
  chargingStartDate?: any | null;
  chargingEndDate?: any | null;
  tenancyOptionRateType: TenancyOptionRateTypeOption;
  pricePerNightOriginal?: number | null;
  pricePerMonthOriginal?: number | null;
  roomTypeId: any;
  specialOfferId?: any | null;
  channelId?: any | null;
  installmentId: any;
  bypassTenancyOptionCheck?: boolean | null;
  studentId?: any | null;
  services: serviceInput[];
  nominationPlanId?: any | null;
}

export interface getInstallmentsForSelectionInput {
  academicYearId: any;
  tenancyOptionId?: any | null;
  tenancyStartDate: any;
  tenancyEndDate: any;
  tenancyOptionRateType: TenancyOptionRateTypeOption;
  pricePerNightOriginal?: number | null;
  pricePerMonthOriginal?: number | null;
  roomTypeId: any;
  bypassTenancyOptionCheck?: boolean | null;
  services: serviceInput[];
  nominationPlanId?: any | null;
}

export interface getOfflineRefundBankAccountConfigInput {
  studentCountryId: any;
}

export interface getPreferenceForFlatInput {
  flatId: any;
  startDate: any;
  endDate: any;
}

export interface getRefundDetailReferenceInfosInput {
  bookingId: any;
}

export interface getSelectedServiceOptionsInfoInput {
  academicYearId: any;
  roomTypeId: any;
  tenancyOptionId?: any | null;
  tenancyStartDate: any;
  tenancyEndDate: any;
  chargingStartDate?: any | null;
  chargingEndDate?: any | null;
  tenancyOptionRateType: TenancyOptionRateTypeOption;
  pricePerNightOriginal?: number | null;
  pricePerMonthOriginal?: number | null;
  services: serviceCalculateInput[];
  nominationPlanId?: any | null;
  bypassTenancyOptionCheck?: boolean | null;
}

export interface getServiceOptionsForSelectionInput {
  academicYearId: any;
  roomTypeId: any;
  tenancyOptionId?: any | null;
  tenancyStartDate: any;
  tenancyEndDate: any;
  chargingStartDate?: any | null;
  chargingEndDate?: any | null;
  tenancyOptionRateType: TenancyOptionRateTypeOption;
  pricePerNightOriginal?: number | null;
  pricePerMonthOriginal?: number | null;
  nominationPlanId?: any | null;
  bypassTenancyOptionCheck?: boolean | null;
}

export interface guarantorInformationInput {
  contactEmail?: string | null;
  firstName?: any | null;
  middleName?: string | null;
  lastName?: any | null;
  title?: string | null;
  dateOfBirth?: any | null;
  mobilePhoneAreaCode?: string | null;
  mobilePhoneNumber?: string | null;
  homePhoneAreaCode?: string | null;
  homePhoneNumber?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  countryTerritory?: string | null;
  countryTerritoryId?: any | null;
  cityTownId?: any | null;
  cityTown?: any | null;
  postcode?: string | null;
  relationship?: RelationshipOptions | null;
}

export interface offlineConfigCustomizedFieldsInput {
  name?: string | null;
  value?: string | null;
}

export interface preRegisterStudentUserInput {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  topLandlordId: string;
  communicationPreferences?: StudentCommunicationPreferencesOptions[] | null;
  heardSource: string;
  interestedPropertyId?: any | null;
  device: DeviceOption;
  preferredLanguage?: string | null;
  mobilePhoneAreaCode?: string | null;
  mobilePhoneNumber?: string | null;
  validationWidth?: number | null;
  validationHeight?: number | null;
}

export interface refundDetailOfflineConfigInput {
  refundDetailId: any;
  bankAccountDetails?: (offlineConfigCustomizedFieldsInput | null)[] | null;
}

export interface registerStudentUserInput {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  topLandlordId: string;
  communicationPreferences?: StudentCommunicationPreferencesOptions[] | null;
  heardSource: string;
  interestedPropertyId?: any | null;
  device: DeviceOption;
  preferredLanguage?: string | null;
  mobilePhoneAreaCode?: string | null;
  mobilePhoneNumber?: string | null;
  validationToken?: string | null;
}

export interface selectBedInput {
  bookingId: any;
  bedId: any;
}

export interface selectServiceOptionsInput {
  bookingId: any;
  services: serviceInput[];
}

export interface serviceCalculateInput {
  id: any;
  serviceOptions: serviceOptionInput;
  incrementType: IncrementTypeOptions;
}

export interface serviceInput {
  id: any;
  serviceOptions: serviceOptionInput;
}

export interface serviceOnRoomTypeInput {
  academicYearId: any;
  tenancyLength?: number | null;
  tenancyStartDate: any;
  tenancyEndDate: any;
  tenancyOptionRateType: TenancyOptionRateTypeOption;
  nominationPlanId?: any | null;
}

export interface serviceOptionInput {
  id: any;
  name: string;
  originalPriceContribution: number;
}

export interface studentChangePasswordInput {
  currentPassword: any;
  newPassword: any;
}

export interface studentConfirmPaymentInput {
  paymentItemIds: any[];
  propertyId: any;
  bookingId: any;
  amount: number;
  confirmationToken?: string | null;
  paymentMethodId?: any | null;
  previewHash: string;
  paymentMethodType: PaymentMethodTypeOptions;
  savePaymentMethod?: boolean | null;
  isDefault?: boolean | null;
  paymentScenario?: PaymentScenarioOptions | null;
}

export interface studentCreateArrivalInformationInput {
  bookingId: any;
  propertyId: any;
  arrivalDate: any;
  timeSlotFrom: string;
  modeOfArrival: ModeOfArrivalOption;
  flightNumber?: string | null;
  message?: string | null;
}

export interface studentCreateBookingInput {
  propertyId: any;
  academicYearId: any;
  tenancyOptionId: any;
  tenancyStartDate: any;
  tenancyEndDate: any;
  tenancyOptionRateType: TenancyOptionRateTypeOption;
  roomTypeId: any;
  bedId?: any | null;
  specialOfferId?: any | null;
  channelId?: any | null;
  installmentId?: any | null;
  installmentDataHash?: string | null;
  device: DeviceOption;
  services?: (serviceInput | null)[] | null;
}

export interface studentEditBookingInput {
  id: any;
  academicYearId: any;
  tenancyOptionId?: any | null;
  tenancyStartDate: any;
  tenancyEndDate: any;
  tenancyOptionRateType: TenancyOptionRateTypeOption;
  roomTypeId: any;
}

export interface studentEditBookingWhenSelectBedInput {
  academicYearId: any;
  tenancyOptionId: any;
  tenancyStartDate: any;
  tenancyEndDate: any;
  tenancyOptionRateType: TenancyOptionRateTypeOption;
  roomTypeId: any;
}

export interface studentFillPersonalDetailInput {
  id: any;
  studentPersonalDetailInput: StudentPersonalDetailInput;
  guarantorInformationInput?: guarantorInformationInput | null;
  bookingReferralInput: bookingReferralInput;
  customFieldValues?: upsertBookingCustomFieldValueInput[] | null;
}

export interface studentGetFlatsForSelectionInput {
  roomTypeId: any;
  academicYearId: any;
  buildingIds: any[];
  floorIndexes: (string | null)[];
  tenancyOptionId?: any | null;
  tenancyStartDate?: any | null;
  tenancyEndDate?: any | null;
  chargingStartDate?: any | null;
  chargingEndDate?: any | null;
  tenancyOptionRateType?: TenancyOptionRateTypeOption | null;
  maxNumOfFlatmates: any;
  genderPreference?: GenderPreferenceOptions | null;
  dietaryPreference?: SelectBedDietaryPreferenceOption | null;
  smokingPreference?: SelectBedSmokingPreferenceOption | null;
  petPreference?: SelectBedPetPreferenceOption | null;
  disabilityAccess?: boolean | null;
  intervalForTurnaround?: any | null;
  nominationPlanId?: any | null;
  sortDirection?: SortDirection | null;
}

export interface studentGetPaymentPreviewInput {
  paymentItemIds: any[];
  propertyId: any;
  bookingId: any;
  amount: number;
  paymentMethodId?: any | null;
  confirmationToken?: string | null;
  paymentMethodType: PaymentMethodTypeOptions;
  savePaymentMethod?: boolean | null;
  isDefault?: boolean | null;
  paymentScenario?: PaymentScenarioOptions | null;
}

export interface studentGetSelectedFlatInfoInput {
  flatId: any;
  roomTypeId: any;
  academicYearId: any;
  tenancyOptionId?: any | null;
  tenancyStartDate?: any | null;
  tenancyEndDate?: any | null;
  chargingStartDate?: any | null;
  chargingEndDate?: any | null;
  tenancyOptionRateType?: TenancyOptionRateTypeOption | null;
  disabilityAccess?: boolean | null;
  nominationPlanId?: any | null;
}

export interface studentInspectionReportItemInput {
  facilityId: any;
  itemName: string;
  itemCategory: InspectionItemCategoryOptions;
  status: InspectionItemStatusOptions;
  proofPhotoUuids?: (string | null)[] | null;
  comment?: string | null;
}

export interface studentSelectInstallmentInput {
  bookingId: any;
  installmentId: any;
  specialOfferId?: any | null;
  channelId?: any | null;
  installmentDataHash: string;
}

export interface studentTriggerChangeEmailInput {
  currentPassword: any;
  newEmail: any;
}

export interface studentUpdateArrivalInformationInput {
  id: any;
  bookingId: any;
  propertyId: any;
  arrivalDate: any;
  timeSlotFrom: string;
  modeOfArrival: ModeOfArrivalOption;
  flightNumber?: string | null;
  message?: string | null;
}

export interface studentUpdateBasicInfoInput {
  firstName: any;
  middleName?: string | null;
  lastName: any;
  mobilePhoneAreaCode: any;
  mobilePhoneNumber: any;
  homePhoneAreaCode?: any | null;
  homePhoneNumber?: any | null;
  title?: string | null;
  dateOfBirth: any;
  gender: GenderOptions;
  nationality: any;
  countryTerritoryId: any;
  cityTownId?: any | null;
  cityTown?: any | null;
  addressLine1: any;
  addressLine2?: string | null;
  postcode?: string | null;
  preferredName: any;
  preferredLanguage?: string | null;
  preferredGenderPronoun?: string | null;
}

export interface studentUpdateEducationInfoInput {
  destinationUniversity: string;
  yearOfStudy: string;
  major?: string | null;
  degreeClassification?: string | null;
  universityCardId?: string | null;
}

export interface studentUpdateInspectionReportInput {
  id: any;
  bookingId: any;
  inspectionReportItems: studentInspectionReportItemInput[];
}

export interface studentUpdateProfilePhotoInput {
  profilePhotoFileUuid: any;
}

export interface studentUpsertBookingGuarantorInput {
  bookingId: any;
  guarantorInformationInput: guarantorInformationInput;
}

export interface updateBookingDocumentInput {
  documentId: any;
  bookingId: any;
  fileUuid: any;
}

export interface updateOutOfRoomRecordInput {
  id: any;
  reason: any;
  awayFrom: any;
  awayTo: any;
}

export interface updateRefundDetailsInput {
  details?: (refundDetailOfflineConfigInput | null)[] | null;
  studentCountryId: any;
}

export interface updateStudentEmergencyContactInput {
  id: any;
  relationship: RelationshipOptions;
  firstName: any;
  middleName?: string | null;
  lastName: any;
  title?: string | null;
  dateOfBirth?: any | null;
  countryTerritoryId: any;
  cityTownId?: any | null;
  cityTown?: any | null;
  addressLine1: string;
  addressLine2?: string | null;
  postcode?: string | null;
  mobilePhoneAreaCode: string;
  mobilePhoneNumber: string;
  homePhoneAreaCode?: string | null;
  homePhoneNumber?: string | null;
  contactEmail: string;
  contactWithoutPermission?: boolean | null;
}

export interface upsertBookingCustomFieldValueInput {
  id?: any | null;
  customFieldId: any;
  customFieldType: CustomFieldTypeOptions;
  value?: any | null;
}

export interface upsertBookingCustomFieldValuesInput {
  bookingId: any;
  customFieldValues: (upsertBookingCustomFieldValueInput | null)[];
}

//==============================================================
// END Enums and Input Objects
//==============================================================
